import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, withStyles } from '@material-ui/core';
import acctLogo from '../../images/AACT_logo.jpg';

function Footer({ classes }) {
  return (
    <Grid
      className={classes.root}
      container
      direction='row'
      justifyContent='space-around'
      alignItems='center'
    >
      <Grid item xs={12} sm={6}>
        <Grid container direction='column'>
          <Grid item>Polo Area Community Theatre</Grid>
          <Grid item>
            <a
              className={classes.link}
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.google.com/maps/place/117+N+Franklin+Ave,+Polo,+IL+61064/@41.9869345,-89.5800602,17z/data=!3m1!4b1!4m5!3m4!1s0x88084dc560f9d425:0xdee025f9318296c2!8m2!3d41.9869345!4d-89.5778715'
            >
              117 N. Franklin Ave., Polo, IL
            </a>
          </Grid>
          <Grid item>PACT Mailing Address:</Grid>
          <Grid item>PO Box 36, Polo, IL 61064</Grid>
          <Grid item>
            <a className={classes.link} href='tel:815-441-0778'>
              815-441-0778
            </a>
          </Grid>
          <Grid item>
            <a className={classes.link} href='mailto:info@polotheatre.org'>
              info@polotheatre.org
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container direction='column'>
          <Grid item>
            <Link className={classes.link} to={'join-newsletter'}>
              Join our Newsletter
            </Link>
          </Grid>
          <Grid item>Proud member of:</Grid>
          <Grid item>
            <img className={classes.logo} src={acctLogo} alt='logo' />
          </Grid>
          <Grid item className={classes.policyLink}>
            <Link className={classes.link} to='policy'>
              Policies
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  root: {
    color: 'white',
    backgroundColor: '#86269B',
    padding: 20,
    textAlign: 'center',
  },

  link: {
    color: '#269b86',
  },

  newsletterLink: {
    color: '#269b86',
    cursor: 'pointer',
  },

  policyLink: {
    marginTop: '2rem',
  },

  logo: {
    width: 120,
  },
};

export default withStyles(styles)(Footer);
